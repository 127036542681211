// Colors
$local-topbar: #ffcc00;
$local-topbar-text: #333333;
$local-bkg: #fcfcfc;
$local-bkg-image: none;
$local-text: #333333;

// Font
$local-text-font: 'Open Sans', sans-serif;
$local-headline-font: 'Oswald', sans-serif;

// Dimensions
$local-topbar-height: 70px;
$local-logo-width: 210px;
$local-logo-margin-top: 30px;
$local-logo-margin-bottom: 1px;
